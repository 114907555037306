
import {fetchData} from "../firebase/fetching";
import {process_date} from "../processing/process_date";
import {process_odd} from "../processing/process_odd";
import {process_prob} from "../processing/process_prob";

import {Container, Row, Col, Form, Table, Stack} from 'react-bootstrap';
import {useEffect, useState} from "react";



const Details = ({settings}) => {
    const game_id = new URLSearchParams(window.location.search).get("id");
    const [bet, setBet] = useState({});
    const [odds, setOdds] = useState({});

    const [filterBookmakers, setFilterBookmaker] = useState(true);
    const [validBookmakers, setValidBookmakers] = useState(settings.bookmakers);


    const process_data = (data) => {
        const val = data[game_id];
        console.log("id: " + game_id)
        console.log("val keys: " + Object.keys(val))
        setBet(val)

        console.log("bet keys: " + Object.keys(val.odds))
        setOdds(val.odds);
    }

    function handleClick (bookmaker, home_team, away_team) {
        // const baseQuery = "search query";
        // const additionalKeywords = ["keyword1", "keyword2", "keyword3"];
        // const completeQuery = `${baseQuery} ${additionalKeywords.join(" ")}`;

        const query = bookmaker + " " + home_team + " " + away_team;

        window.open(`https://www.google.com/search?q=${encodeURIComponent(query)}`, '_blank', 'noopener,noreferrer');
    }

    const handleCheckboxChange = (event) => {
        setFilterBookmaker(event.target.checked);
        if (event.target.checked) {
            setValidBookmakers(settings.bookmakers);
        } else {
            setValidBookmakers([]);
        }
        // Additional functionality can be triggered here
    };

    function getColor(value, prediction, which, max_profit_idx) {
        if (which !== max_profit_idx) {
            return "";
        }
        // console.log("processing " + which)
        // console.log("value before: " + value)
        // console.log("prediction before: " + prediction)
        // console.log(" typeof value: " + typeof value)
        // console.log(" typeof prediction: " + typeof prediction)

        const val = parseFloat(process_odd(value, "EU"));
        const pred = parseFloat(process_prob(prediction, "EU"));

        // console.log(" typeof val: " + typeof val)
        // console.log(" typeof pred: " + typeof pred)
        //
        //
        // if (isNaN(val) || isNaN(pred)) {
        //     console.log("---------------- ALERT ALERT ------------------------")
        // }
        //
        //
        // console.log("value after: " + val)
        // console.log("prediction after: " + pred)
        if (val > pred) {
            console.log("bg-success")
            return "green-background";
        } else {
            console.log("bg-danger")
            return "";
        }
    }

    // on page load
    useEffect(() => {
        fetchData(settings, "Upcoming")
            .then(data => {
                process_data(data)
            })
            .catch(error => {
                console.error("Error during fetchData in initial useEffect: ", error);
            });
    }, []);

    // on settings change
    useEffect(() => {
        fetchData(settings, "Upcoming")
            .then(data => {
                process_data(data)
            })
            .catch(error => {
                console.error("Error during fetchData in initial useEffect: ", error);
            });
    }, [settings]);

    useEffect(() => {
        if (filterBookmakers) {
            setValidBookmakers(settings.bookmakers);
        } else {
            setValidBookmakers([]);
        }
    }, [settings]);

    return (
        <section className="bg-white pt-5">
            <Container className="text-center" id="details">
                {/*// , ${process_date(bet.date)*/}
                <h1>{bet.home_team} vs {bet.away_team} </h1>
                <h2>{bet.date && process_date(bet.date, settings.timezone)} </h2>

                {/*<div >*/}
                {/*    <strong>Your Bookmakers:</strong> {validBookmakers.map(bookmaker => bookmaker.label).join(', ')}*/}

                {/*</div>*/}
                <Stack direction="horizontal" gap={3} className={"mt-3"}>
                    <div>
                        <p  className={"align-bottom text-start"}>OffsideOdds suggests betting <strong> {bet["max_profit_idx"]} </strong> on <strong>{bet[bet["max_profit_idx"] + "_max_bookie"]} </strong> for an average net profit of <strong>{(bet?.max_profit_value * settings.investmentSize).toFixed(2)} {settings.currency}</strong>! <br/>
                        Click on a row to go bet on that bookmaker!
                        </p>
                    </div>
                    <Form className={"ms-auto"}>
                        <Form.Check // prettier-ignore
                            reverse
                            type="switch"
                            id="custom-switch"
                            label="Filter by your bookmakers"
                            checked={filterBookmakers}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Stack>


                < Table hover responsive >
                    <thead>
                    <tr>
                        <th>Bookmaker</th>
                        <th>Home Odds</th>
                        <th>Tie Odds</th>
                        <th>Away Odds</th>
                    </tr>
                    </thead>
                    <tbody className={"table-group-divider"} >
                        <tr key={"Offside Odd"}  >
                            <td className={"grey-background"}> <strong> {"Offside Odd"} </strong> </td>
                            <td className={"grey-background"}>{process_prob(bet.home_prediction, settings.oddsFormat)} </td>
                            <td className={"grey-background"}>{process_prob(bet.tie_prediction, settings.oddsFormat)}  </td>
                            <td className={"grey-background"}>{process_prob(bet.away_prediction, settings.oddsFormat)} </td>
                        </tr>
                    </tbody>
                    <tbody className={"table-group-divider"}>
                    {
                        (() => {
                            // Sort entries by values at bet.max_prediction_idx
                            const sortedEntries = Object.entries(odds)
                                .filter(([bookie, _]) => validBookmakers.length === 0 || validBookmakers.some(b => b.label === bookie))
                                .sort((a, b) => {
                                    // Assuming values at the index are numbers
                                    return parseFloat(b[1][bet.max_profit_idx]) - parseFloat(a[1][bet.max_profit_idx]);
                                });
                            // console.log("idx of max: " + bet.max_profit_idx)
                            // console.log("sortedEntries: " + sortedEntries)

                            // Map sorted entries to table rows
                            return sortedEntries.map(([bookie, values]) => (
                                <tr key={bookie} onClick={() => handleClick(bookie, bet.home_team, bet.away_team)}>
                                    <td>{bookie}</td>
                                    <td className={getColor(values.home, bet.home_prediction, "home", bet.max_profit_idx)}>{process_odd(values.home, settings.oddsFormat)}</td>
                                    <td className={getColor(values.tie, bet.tie_prediction, "tie", bet.max_profit_idx)}>{process_odd(values.tie, settings.oddsFormat)}</td>
                                    <td className={getColor(values.away, bet.away_prediction, "away", bet.max_profit_idx)}>{process_odd(values.away, settings.oddsFormat)}</td>
                                </tr>
                            ));
                        })()
                    }
                    </tbody>
                </Table>
            </Container>
        </section>
    );
}

export default Details;

