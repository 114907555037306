import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "./firebase";
import {doc, setDoc} from "firebase/firestore";

export function handleRegister(email, password, username, settings, setSettings) {
    console.log(email, username)
    const { user: _, username: __, ...otherSettings } = settings;
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            return setDoc(doc(db, "users" , user.uid), {...otherSettings, username: username})
                .then((r) => {
                    console.log("Document successfully written!");
                    setSettings ({...otherSettings, user: user, username: username});
                    return r;
                }).catch((firestoreError) => {
                    console.error("Firestore error: ", firestoreError.message);
                    throw firestoreError;  // Re-throw the error to be handled by the outer catch block
                });
        })
        .catch((authError) => {
            console.error("Authentication error: ", authError.message);
            throw authError;
        });
}
