import {signInWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "./firebase";
import {doc, getDoc} from "firebase/firestore";
import {get_default_settings} from "./fetching";

export function handleLogin(email, password, setSettings) {
    // Implement Firebase login logic here
    return signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
            return getDoc(doc(db, "users" , user.user.uid))
                .then((r) => {
                    console.log("Document successfully written!");
                    const defaultSettings = get_default_settings()
                    setSettings({...defaultSettings, ...r.data(), user: user.user});
                    console.log("r.data(): ", r.data());
                    return r;
                }).catch((firestoreError) => {
                    console.error("Firestore error: ", firestoreError.message);
                    throw firestoreError;  // Re-throw the error to be handled by the outer catch block
                });
        })
        .catch((error) => {
            console.error("Error signing into user: " + error.message);
            throw error;
        });
}
