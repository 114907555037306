import {DateTime} from "luxon";

export function process_date(date, timezone) {
    // Check if 'date' is a Firestore timestamp and convert to JS Date object if so
    const date_object = date.toDate ? date.toDate() : new Date(date.seconds * 1000);

    // Convert JS Date to Luxon DateTime
    let luxonDate = DateTime.fromJSDate(date_object);

    if (timezone) {
        luxonDate = luxonDate.setZone(timezone);
    }

    // Format and return the date
    return luxonDate.toFormat('LLL dd - HH:mm');
}