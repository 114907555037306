import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {useEffect, useState} from "react";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBfSFSOqYF4Uc5CYr0q0H0EdWPN4b8dWf0",
    authDomain: "sports-database-37221.firebaseapp.com",
    databaseURL: "https://sports-database-37221-default-rtdb.firebaseio.com",
    projectId: "sports-database-37221",
    storageBucket: "sports-database-37221.appspot.com",
    messagingSenderId: "467385926959",
    appId: "1:467385926959:web:0ef69f21a078d985b54dda",
    measurementId: "G-0M3SQJ0MWZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


export { app, auth, db};


