export function process_prob(probability, oddsFormat) {
    // Convert the percentage to a decimal for calculations
    const decimalProbability = parseFloat(probability) / 100;

    let convertedOdd;

    switch (oddsFormat) {
        case "EU":
            // Convert Probability to EU odds
            convertedOdd = (1 / decimalProbability).toFixed(2);
            break;
        case "American":
            // Convert Probability to American odds
            if (decimalProbability >= 0.5) {
                convertedOdd = `-${(100 / decimalProbability - 100).toFixed(0)}`;
            } else {
                convertedOdd = `+${((100 / decimalProbability - 100).toFixed(0))}`;
            }
            break;
        case "Probability":
            // Ensure the probability is in the correct percentage format
            convertedOdd = toString(probability).includes('%') ? probability : probability + '%';
            break;
        default:
            // Default to displaying the probability in percentage format
            convertedOdd = probability.includes('%') ? probability : probability + '%';
    }

    return convertedOdd;
}
