
// LoginModal.js
import React, { useState } from 'react';
import {Modal, Button, ListGroup} from 'react-bootstrap';
import Select from 'react-select';


const BookmakerModal = ({show, setShow, settings, setSettings}) => {

    const [selectedBookmakers, setSelectedBookmakers] = useState(settings.bookmakers || []);

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        setSettings(settings => ({
            ...settings,
            bookmakers: selectedBookmakers
        }));
        handleClose()
    }
    const bookies = ['1xBet', 'Betclic.fr', 'Betsafe', 'Betsson', 'Betway', 'Dafabet', 'Interwetten', 'Marathonbet', 'NordicBet', 'Pinnacle', 'William Hill', 'bet365.it', 'bet365', 'eFortuna.pl', 'iFortuna.cz', 'iFortuna.sk']
    const options = bookies.map(bookie => ({value: bookie, label: bookie}))

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Bookmakers </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedBookmakers.length > 0 && (
                    <ListGroup className="mb-3">
                        {selectedBookmakers.map(bookmaker => (
                            <ListGroup.Item key={bookmaker.value}>{bookmaker.label}</ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
                <Select
                    isMulti
                    value={selectedBookmakers}
                    onChange={setSelectedBookmakers}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
                {/*<Dropdown title={"Odds Format: " + selectedOddsFormat} onSelect={setSelectedOddsFormat} >*/}
                {/*    <Dropdown.Toggle variant="dark" id="dropdown-basic">*/}
                {/*        {selectedOddsFormat}*/}
                {/*    </Dropdown.Toggle>*/}
                {/*    <Dropdown.Menu>*/}
                {/*        {oddFormats.map((value) => (*/}
                {/*            <Dropdown.Item key={value} eventKey={value}>*/}
                {/*                {value}*/}
                {/*            </Dropdown.Item>*/}
                {/*        ))}*/}
                {/*    </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit } >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BookmakerModal;