import {fetchData} from "../firebase/fetching";
import {process_date} from "../processing/process_date";
import {process_odd} from "../processing/process_odd";
import {process_prob} from "../processing/process_prob";

import {Container, Row, Col, Form, Table, Overlay, Tooltip, OverlayTrigger, Stack} from 'react-bootstrap';
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MyTooltip} from "../tooltip";



const Featured = ({settings, setSettings}) => {
    const [bets, setBets] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        fetchData(settings, "Featured")
            .then(data => setBets(data))
            .catch(error => {
                console.error("Error during fetchData in initial useEffect: ", error);
            });
    }, []);

    useEffect(() => {
        fetchData(settings, "Featured")  // Using "Featured" again. If you meant to use "type" instead, then replace "Featured" with type.
            .then(data => setBets(data))
            .catch(error => {
                console.error("Error during fetchData in settings useEffect: ", error);
            });
    }, [settings]);

    const handleClick = (id) => {
        navigate(`/details?id=${id}`);
    }

    const handleChange = (e) => {
        setSettings({...settings, investmentSize: e.target.value})
    }



    return (
        <section className="bg-white pt-5">
            <Container className="text-center" id="featured">
                <h1>Featured Bets</h1>
                {/*<Row xs={"auto"} className={"justify-content-start"} >*/}
                {/*    <p  className={"align-bottom"}>Click on any of these games to see more info! </p>*/}
                {/*</Row>*/}
                {/*<Row className="justify-content-end">*/}
                {/*    <Col xs="auto">*/}
                {/*        <Form.Label htmlFor="investmentSize">Investment size:</Form.Label>*/}
                {/*    </Col>*/}
                {/*    <Col xs="auto">*/}
                {/*        <Form.Control*/}
                {/*            type="number"*/}
                {/*            defaultValue={settings.investmentSize}*/}
                {/*            id="investmentSize"*/}
                {/*            onChange={(e) => handleChange(e)}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Stack direction="horizontal" gap={3} className={"mt-3"}>
                    <div>
                        <p  className={"align-bottom text-start"}>
                            Games are ordered by profitability.  <br/>
                            Click on any of these games to see more info!
                        </p>
                    </div>
                    <div className="ms-auto ">
                        <Form.Label htmlFor="investmentSize">Investment size:</Form.Label>
                    </div>
                    <div >
                        <Form.Control
                            type="number"
                            defaultValue={settings.investmentSize}
                            id="investmentSize"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </Stack>


                <Table hover  responsive>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Game</th>
                            <th>
                                Prediction{' '}
                                <MyTooltip text="The outcome we predict to maximize profitability." />
                            </th>
                            <th>Minimum Fair Value{' '}
                                <MyTooltip text={`"Fair Value" at which the expected return is exactly zero. Any "higher value" (lower for probabilities) is worth betting on in theory`} />
                            </th>
                            <th>Max offered{' '}
                                <MyTooltip text="Max value offered by one of the bookmakers that we follow." />
                            </th>
                            <th>Average expected return{' '}
                                <MyTooltip text="Expected return based on our prediction probability, the max offer and your investment size. Investment + expected net profit." /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(bets).map(([id, bet]) => (
                            <tr key={id} onClick={() => handleClick(bet.id)}>
                                <td style={{whiteSpace: "nowrap"}}>{process_date(bet.date, settings.timezone)}</td>
                                <td>
                                    <Row style={{ flexWrap: 'nowrap' }} className="gx-0">
                                        <Col className="text-end flex-grow-1 me-2">{bet.home_team}</Col>
                                        <Col className="text-center" xs="auto">vs</Col>
                                        <Col className="text-start flex-grow-1 ms-2">{bet.away_team}</Col>
                                    </Row>
                                </td>
                                <td>{bet.max_profit_idx}</td>
                                <td>{process_prob(bet.max_profit_prediction, settings.oddsFormat)}</td>
                                <td>{process_odd(bet.max_profit_odd, settings.oddsFormat)}</td>
                                <td>{((bet.max_profit_value + 1) * settings.investmentSize).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </section>
    );
}

export default Featured;

