
// LoginModal.js
import React, { useState } from 'react';
import {Modal, Button} from 'react-bootstrap';
import TimezoneSelect from "react-timezone-select";


const TimezoneModal = ({show, setShow, settings, setSettings}) => {

    const [selectedTimezone, setSelectedTimezone] = useState(settings.timezone)

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        setSettings(settings => ({
            ...settings,
            timezone: selectedTimezone.value
        }));
        handleClose()
    }

    // timezones ={
    //     ...allTimezones,
    //     'America/Lima': 'Pittsburgh',
    //     'Europe/Berlin': 'Frankfurt',
    // }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Timezone: {selectedTimezone.value ? selectedTimezone.value.split("/")[1] : selectedTimezone.split("/")[1]} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TimezoneModal;