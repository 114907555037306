
// LoginModal.js
import React, { useState } from 'react';
import {Modal, Button, Form, NavDropdown} from 'react-bootstrap';
import {handleLogin} from "../firebase/handle_login";
import {handleRegister} from "../firebase/handle_register";
import ResetPasswordModal from "./reset_password_modal";


const LoginModal = ({show, setShow, setSettings, setRegistered}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [showReset, setShowReset] = useState(false);
    const handleShowReset = () => setShowReset(true);


    const handleClose = () => {
        setShow(false);
        setModalMessage('');
    }

    const handleSubmit = () => {
        handleLogin(email, password, setSettings)
            .then(() => {
                setRegistered(true);
                handleClose();
            }).catch((error) => {
            const errorMessage = error.message || "An unknown error occurred";
            console.error(errorMessage);
            setModalMessage(errorMessage);
        });
    };


    return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                    </Form>
                    {modalMessage && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {modalMessage}
                        </div>
                    )}
                    <Button variant="link" className="text-dark" onClick={handleShowReset}>Reset Password</Button>
                    <ResetPasswordModal show={showReset} setShow={setShowReset} email={""} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} >
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
    );
};

export default LoginModal;