
// RegisterModal.js
import React, { useState } from 'react';
import {Modal, Button, Form, NavDropdown, Navbar} from 'react-bootstrap';
import {handleRegister} from "../firebase/handle_register";




const RegisterModal = ({show, setShow, settings, setSettings, setRegistered}) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShow(false);
        setModalMessage('');
    }
    const handleSubmit = () => {
        handleRegister(email, password, username, settings, setSettings)
            .then(() => {
                setRegistered(true);
                handleClose();
            }).catch((error) => {
                const errorMessage = error.message || "An unknown error occurred";
                console.error(errorMessage);
                setModalMessage(errorMessage);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicUsername">
                        <Form.Label>username</Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                </Form>
                {modalMessage && (
                    <div className="alert alert-danger mt-3" role="alert">
                        {modalMessage}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} >
                    Register
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default RegisterModal;