import { Container, Image } from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import logo from "../assets/logo-dark-corrected.png";
import {useEffect, useRef} from "react";
import Featured from "./tables/featured";

const Home = ({settings, setSettings}) => {
    useEffect(() => {
        // Set the background color when the component mounts
        document.body.classList.add("bg-dark");

        // Remove the bg-dark class from the body when the component unmounts
        return () => {
            document.body.classList.remove("bg-dark");
        };
    }, []);

    return (
        <div>
            <section className="bg-dark min-vh-100 overflow-hidden">
                <Container className="text-center text-white">
                    <h1 className="text-white"> State-of-the-art predictions for betting </h1>
                    <p> At OffsideOdds, we use extensive data and custom machine learning models to find profit opportunities in football betting odds. </p>
                    <a href="/#home">
                        <Image src={logo} width="960" height="540" alt="Logo" fluid />
                    </a>
                </Container>
            </section>
            <section>
                <Container className="container text-center text-white">

                    <Container className="row" id="home">
                        <Container className="col-md-4 px-5">
                            <h3>OUR PURPOSE</h3>
                            <p>
                                Offside Odds is dedicated to enhancing profitability through a balance of profit and discretion. Unlike many betting sites promising guaranteed wins, we emphasize long-term gains by identifying lucrative margins in straightforward, popular bets.
                            </p>
                        </Container>
                        <Container className="col-md-4 px-5">
                            <h3>DISCRETION</h3>
                            <p>
                                We extend your betting lifespan by focusing on the BIG5 football leagues and offering predictions on common bets like home win, tie, and away win. Following our <Link className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" to="betting_principles.html">betting principles</Link>, you'll seamlessly blend into the casual bettor profile.
                            </p>
                        </Container>
                        <Container className="col-md-4 px-5">
                            <h3>PROFITABILITY</h3>
                            <p>
                                We build our own, advanced, Machine Learning models to determine match outcome probabilities, aiding in bet profitability assessment based on "expected profit". Using our predicted probabilities, we identify for you bets with the highest expected profit.
                            </p>
                        </Container>
                    </Container>

                </Container>
            </section>
            <Featured settings={settings} setSettings={setSettings}/>
        </div>

    );
};

export default Home;