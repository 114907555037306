
import {fetchData} from "../firebase/fetching";
import {process_date} from "../processing/process_date";
import {process_odd} from "../processing/process_odd";
import {process_prob} from "../processing/process_prob";

import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {MyTooltip} from "../tooltip";


const Upcoming = ({settings}) => {
    const [bets, setBets] = useState([]);

    const navigate = useNavigate();

    // on page load
    useEffect(() => {
        fetchData(settings, "Upcoming")
            .then(data => setBets(data))
            .catch(error => {
                console.error("Error during fetchData in initial useEffect: ", error);
            });
    }, []);

    // on settings change
    useEffect(() => {
        fetchData(settings, "Upcoming")
            .then(data => setBets(data))
            .catch(error => {
                console.error("Error during fetchData in settings useEffect: ", error);
            });
    }, [settings]);

    const handleClick = (id) => {
        navigate(`/details?id=${id}`);
    }

    function getColor(value) {
        if (value <= .1) {
            return "";
        } else if (value <= .25) {
            return "bg-success-subtle";
        } else {
            return "bg-success";
        }
    }


    return (
        <section className="bg-white pt-5">
            <Container className="text-center" id="upcoming">
                <h1>Upcoming Games</h1>

                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Game</th>
                        <th>Home Prediction{' '}
                            <MyTooltip text='Our "Fair value" defined by our outcome probability. Bookmakers offering higher values are worth betting on' />
                        </th>
                        <th>Tie Prediction{' '}
                            <MyTooltip text='Our "Fair value" defined by our outcome probability. Bookmakers offering higher values are worth betting on' />
                        </th>
                        <th>Away Prediction{' '}
                            <MyTooltip text='Our "Fair value" defined by our outcome probability. Bookmakers offering higher values are worth betting on' />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(bets).slice(0, 100).map(([id, bet]) => (
                        <tr key={id} onClick={() => handleClick(bet.id)} >
                            <td style={{whiteSpace: "nowrap"}}>{process_date(bet.date, settings.timezone)}</td>
                            <td>
                                <Row style={{ flexWrap: 'nowrap' }} className="gx-0">
                                    <Col className="text-end flex-grow-1 me-2">{bet.home_team}</Col>
                                    <Col className="text-center">vs</Col>
                                    <Col className="text-start flex-grow-1 ms-2">{bet.away_team}</Col>
                                </Row>
                            </td>
                            <td className={getColor(bet.home_profit)}> {process_prob(bet.home_prediction, settings.oddsFormat)} </td>
                            <td className={getColor(bet.tie_profit)}> {process_prob(bet.tie_prediction, settings.oddsFormat)} </td>
                            <td className={getColor(bet.away_profit)}> {process_prob(bet.away_prediction, settings.oddsFormat)} </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Container>
        </section>
    );
}

export default Upcoming;

