import * as React from "react";
import Header from "./components/header";
import Home from "./components/home"
import Settings from "./components/settings"
import Upcoming from "./components/tables/upcoming"
import Details from "./components/tables/details"
import {fetchSettings} from "./components/firebase/fetching";
import {createBrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import FreeTrial from "./components/free-trial";


const router = createBrowserRouter([
    {path: "*",Component: Root}
]);
export default function App() {
    return <RouterProvider router={router} />;
}

function Root() {
    const [settings, setSettings] = React.useState(fetchSettings());

    React.useEffect(() => {
        localStorage.setItem('cachedSettings', JSON.stringify(settings));
    }, [settings]);


    return (
        <div>
            <Header settings={settings} setSettings={setSettings}></Header>
            <Routes>
                <Route path="/" element={<Home settings={settings} setSettings={setSettings}/>} />
                <Route path="/upcoming" element={<Upcoming settings={settings} />} />
                <Route path="/details" element={<Details settings={settings} />} />
                <Route path="/account" element={<Settings settings={settings} setSettings={setSettings}/>} />
                <Route path="/free-trial" element={<FreeTrial settings={settings} setSettings={setSettings}/>} />
            </Routes>
        </div>
    );
}


// useEffect(() => {
//
//     const handleBeforeUnload = (event) => {
//         localStorage.setItem('userSettings', JSON.stringify(settings));
//     };
//     window.addEventListener('beforeunload', handleBeforeUnload);
//
//     // Cleanup the event listener when the component is unmounted
//     return () => {
//         window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
// }, [settings]);
//
// function App() {
//
//
//     createRoot(document.getElementById("root")).render(
//         <RouterProvider router={router} />
//     );
//
//     return (
//         <RouterProvider router={router}>
//             <div>
//                 <Header settings={settings} setSettings={setSettings}></Header>
//             </div>
//         </RouterProvider>
//     );
// }