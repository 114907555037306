import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { handleResetPassword } from "../firebase/handle_reset_password";  // Adjust the path

const ResetPasswordModal = ({ show, setShow, email: initialEmail}) => {
    const [email, setEmail] = useState(initialEmail);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShow(false);
        setModalMessage('');
    }

    const handleSubmit = () => {
        handleResetPassword(email)
            .then(() => {
                setModalMessage('Password reset email sent successfully.');
                handleClose();
            }).catch((error) => {
            const errorMessage = error.message || "An unknown error occurred";
            console.error(errorMessage);
            setModalMessage(errorMessage);
        });
    };

    const isEmailReadOnly = initialEmail !== '';

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly={isEmailReadOnly}
                        />
                    </Form.Group>
                </Form>
                {modalMessage && (
                    <div className="alert alert-danger mt-3" role="alert">
                        {modalMessage}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} >
                    Confirm Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetPasswordModal;
