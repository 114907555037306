export function process_odd(odd, oddsFormat) {
    let convertedOdd;

    switch (oddsFormat) {
        case "EU":
            convertedOdd = odd.toFixed(2);
            break;
        case "American":
            if (odd >= 2.00) {
                convertedOdd = `+${((odd - 1) * 100).toFixed(0)}`;
            } else {
                convertedOdd = (-100 / (odd - 1)).toFixed(0);
            }
            break;
        case "Probability":
            convertedOdd = (1 / odd * 100).toFixed(2) + '%';
            break;
        default:
            convertedOdd = odd.toFixed(2);
    }

    return convertedOdd;
}
