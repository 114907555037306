
// LoginModal.js
import React, { useState } from 'react';
import {Modal, Button } from 'react-bootstrap';
import Select from 'react-select';


const OddsFormatModal = ({show, setShow, settings, setSettings}) => {

    const [selectedOddsFormat, setSelectedOddsFormat] = useState(settings.oddsFormat)

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        setSettings(settings => ({
            ...settings,
            oddsFormat: selectedOddsFormat
        }));
        handleClose()
    }
    const options = [
        { value: 'EU', label: 'EU' },
        { value: 'American', label: 'American' },
        { value: 'Probability', label: 'Probability' }
    ]

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select odds format </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    defaultValue={selectedOddsFormat}
                    onChange={(format) => setSelectedOddsFormat(format.value)}
                    options={options}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit } >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OddsFormatModal;