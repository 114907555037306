import React, {useEffect, useState} from 'react';
import TimezoneSelector from './deprecated/timezone_selector';
import OddsFormatSelect from "./deprecated/odds_format";
import {Alert, Button, Form, Nav, Tab, Tabs} from "react-bootstrap";
import ResetPasswordModal from './modals/reset_password_modal';
import "./settings.css";
import {handle_save_preferences, handle_save_profile} from "./firebase/handle_save";

function Settings({ settings, setSettings }) {

    const [email, setEmail] = useState(settings.user ? settings.user.email : '');
    const [username, setUsername] = useState(settings.username);

    useEffect(() => {
        // Set the background color when the component mounts
        document.body.classList.add("bg-secondary");

        // Remove the bg-dark class from the body when the component unmounts
        return () => {
            document.body.classList.remove("bg-secondary");
        };
    }, []);

    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');  // 'success' for success, 'danger' for error


    const [showReset, setShowReset] = useState(false);
    const handleShowReset = () => setShowReset(true);

    const saveProfileChange = () => {
        const tempSettings = {...settings, username: username };
        setSettings(tempSettings);
        localStorage.setItem('settings', JSON.stringify(tempSettings));
        console.log(tempSettings.user);
        // console.log(email)
        // console.log(typeof email)
        handle_save_profile(email)
            .then(() => {
                setAlertType('success');
                setAlertMessage('Profile settings were successfully saved.');
            })
            .catch((error) => {
                console.error(error.message);
                setAlertType('danger');
                setAlertMessage(`Failed to save profile settings:  ${error.message}`);
            });
    };


    return (
        <section className="settings-container">

            <div className="settings-box">
                <h2 >Settings</h2>


                {settings.user ?
                    <div>
                        <Form>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={username}
                                    name="username"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Form.Group>
                            <Button variant="link" className="text-dark" onClick={handleShowReset}>Reset Password</Button>
                            <ResetPasswordModal show={showReset} setShow={setShowReset} email={settings.user.email} />
                        </Form>
                        <div className="d-flex">
                            <Button variant="secondary" onClick={saveProfileChange} className="mt-4 ms-auto">
                                Apply Changes
                            </Button>

                        </div>
                        <div className="d-flex">
                            {alertMessage && (
                                <Alert className={"ms-auto"} variant={alertType} onClose={() => setAlertMessage('')} dismissible>
                                    {alertMessage}
                                </Alert>
                            )}
                        </div>
                    </div>  :
                    <div>
                        <h1> Login, Register or return to home page. </h1>
                    </div>
                }

            </div>
        </section>
    );
}

export default Settings;






// import React, { useState, useEffect } from 'react';
// import { doc, updateDoc, getDoc, collection } from 'firebase/firestore';
// import { auth, db } from './firebase'; // Adjust the path accordingly
// import TimezoneSelect from './timezone_selector';
//
// function SettingsPage() {
//   const [timezone, setTimezone] = useState('');
//   const [oddsFormat, setOddsFormat] = useState('');
//   const [user, setUser] = useState(null);
//
//   // Fetch the current user's settings when the component mounts
//   useEffect(() => {
//     const fetchUserSettings = async () => {
//       if (user) {
//         const userDocRef = doc(collection(db, 'users'), user.uid);
//         const docSnapshot = await getDoc(userDocRef);
//         if (docSnapshot.exists()) {
//           const userData = docSnapshot.data();
//           setTimezone(userData.timezone || '');
//           setOddsFormat(userData.odds_format || '');
//         }
//       }
//     };
//
//     fetchUserSettings();
//   }, [user]);
//
//   const handleUpdateSettings = async () => {
//     if (user) {
//       const userDocRef = doc(collection(db, 'users'), user.uid);
//       await updateDoc(userDocRef, {
//         timezone: timezone,
//         odds_format: oddsFormat
//       });
//       alert('Settings updated successfully!');
//     }
//   };
//
//   return (
//     <div>
//       <h2>User Settings</h2>
//
//       <div>
//         <label>
//           Timezone:
//           <input
//             type="text"
//             value={timezone}
//             onChange={(e) => setTimezone(e.target.value)}
//           />
//         </label>
//       </div>
//
//       <div>
//         <label>
//           Odds Format:
//           <input
//             type="text"
//             value={oddsFormat}
//             onChange={(e) => setOddsFormat(e.target.value)}
//           />
//         </label>
//       </div>
//
//       <button onClick={handleUpdateSettings}>
//         Update Settings
//       </button>
//     </div>
//   );
// }
//
// export default SettingsPage;
