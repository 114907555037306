import React, {useEffect, useState} from 'react';
import TimezoneSelector from './deprecated/timezone_selector';
import OddsFormatSelect from "./deprecated/odds_format";
import {Alert, Button, Col, Form, Nav, Row, Tab, Tabs} from "react-bootstrap";
import ResetPasswordModal from './modals/reset_password_modal';
import "./settings.css";
import {handle_save_preferences, handle_save_profile} from "./firebase/handle_save";
import {handleRegister} from "./firebase/handle_register";
import RegisterModal from "./modals/register_modal";
import {handleSignUp} from "./firebase/handleSignUp";
import LoginModal from "./modals/login_modal";

function FreeTrial({ settings, setSettings }) {

    const trial_start = "June 2024";

    useEffect(() => {
        // Set the background color when the component mounts
        document.body.classList.add("bg-dark");

        // Remove the bg-dark class from the body when the component unmounts
        return () => {
            document.body.classList.remove("bg-dark");
        };
    }, []);


    useEffect(() => {
        setSignedUp(settings.signedUp)
    }, [settings.signedUp]);

    useEffect(() => {
        setSignedUp(settings.signedUp)
        setRegistered(!!settings.user)
    }, [settings.user]);


    const [email, setEmail] = useState(settings.user ? settings.user.email : '');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');  // 'success' for success, 'danger' for error

    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [registered, setRegistered] = useState(!!settings.user);
    const [signedUp, setSignedUp] = useState(settings.signedUp);


    const handleUserSignUp = () => {
        console.log("settings.signedUp: " + settings.signedUp)
        handleSignUp()
            .then(() => {
                setAlertMessage("Successfully registered!");
                setAlertType("success");
                setSignedUp(true);
            })
            .catch((error) => {
                const errorMessage = error.message || "An unknown error occurred";
                console.error(errorMessage);
                setAlertMessage(errorMessage);
                setAlertType("danger");
            });
    }
    const handleLoginClick = () => {
        setShowLogin(true);
    }
    const handleRegisterClick = () => {
        setShowRegister(true);
    }


    return (
        <section className="settings-container">

            <div className="settings-box">
                <h1> Free trial coming soon! </h1>

                {registered ? (
                    <div>
                        {signedUp ? (
                            <div>
                                <h5> You are signed up for the free trial. </h5>
                                <h5> Sit back and relax until {trial_start}!  </h5>
                            </div>
                        ) : (

                            <div>
                                <h5> Sign up for the free trial in {trial_start} </h5>

                                {/*<Form>*/}
                                {/*    <Form.Group className="mb-3" controlId="email">*/}
                                {/*        <Form.Label>Email</Form.Label>*/}
                                {/*        <Form.Control*/}
                                {/*            type="email"*/}
                                {/*            value={email}*/}
                                {/*            name="email"*/}
                                {/*            onChange={(e) => setEmail(e.target.value)}*/}
                                {/*        />*/}
                                {/*    </Form.Group>*/}
                                {/*</Form>*/}
                                <div className="d-flex">
                                    <Button variant="secondary" onClick={handleUserSignUp} className="mt-4 ms-auto">
                                        Sign up
                                    </Button>
                                </div>
                                <div className="d-flex">
                                    {alertMessage && (
                                        <Alert className={"ms-auto"} variant={alertType} onClose={() => setAlertMessage('')} dismissible>
                                            {alertMessage}
                                        </Alert>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    ) : (
                    <div>
                        <h5> Please register an account or log in to sign up to the free trial. </h5>
                        <Row className="justify-content-end">
                            <Col xs={"auto"}>
                                <Button onClick={handleLoginClick} className="dark-grey-background mt-4 ms-auto border-black">
                                    Log in
                                </Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button onClick={handleRegisterClick} className="green-background mt-4 ms-auto border-black">
                                    Register
                                </Button>
                            </Col>
                        </Row>
                        <div className="d-flex">
                            {alertMessage && (
                                <Alert className={"ms-auto"} variant={alertType} onClose={() => setAlertMessage('')} dismissible>
                                    {alertMessage}
                                </Alert>
                            )}
                        </div>
                        <LoginModal show={showLogin} setShow={setShowLogin} setSettings={setSettings} setRegistered={setRegistered}/>
                        <RegisterModal show={showRegister} setShow={setShowRegister} settings={settings} setSettings={setSettings} setRegistered={setRegistered}/>
                    </div>

                    )
                }



            </div>
        </section>
    );
}

export default FreeTrial;




