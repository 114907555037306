
// LoginModal.js
import React, { useState } from 'react';
import {Modal, Button, Row, Form, Col} from 'react-bootstrap';
import Select from 'react-select';

const InvestmentSizeModal = ({show, setShow, settings, setSettings}) => {

    const [selectedInvestmentSize, setSelectedInvestmentSize] = useState(settings.investmentSize)
    const [currency, setCurrency] = useState(settings.currency)

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        console.log("selectedInvestmentSize: ", selectedInvestmentSize)
        console.log("currency: ", currency)
        console.log("settings.currency: ", settings.currency)
        setSettings(settings => ({
            ...settings,
            investmentSize: selectedInvestmentSize,
            currency: currency
        }));
        handleClose()
    }

    const currencyOptions = [
        { value: '$', label: '$ - USD' },
        { value: '€', label: '€ - EUR' },
        { value: '£', label: '£ - GBP' },
        { value: '¥', label: '¥ - JPY' },
        { value: '¥', label: '¥ - CNY' },
        { value: '₹', label: '₹ - INR' },
        { value: 'CAD', label: 'C$ - CAD' },
        { value: 'AUD', label: 'A$ - AUD' },
        { value: 'CHF', label: 'CHF - Francs' },
        { value: '₽', label: '₽ - RUB' },
        { value: '₩', label: '₩ - KRW' },
        { value: 'R$', label: 'R$ - BRL' },
        { value: 'MXN', label: 'MX$ - MXN' },
        { value: 'R', label: 'R - ZAR' },
        { value: 'SGD', label: '$ - SGD' },
        { value: 'HKD', label: '$ - HKD' },
        { value: 'kr', label: 'kr - SEK' },
        { value: 'NZD', label: 'NZ$ - NZD' },
    ];
    const defaultValue = currencyOptions.find(option => option.value === currency);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>  Select an investment size and currency  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={3.5} xs="auto">
                        <Form.Label htmlFor="investmentSize">Investment size:</Form.Label>
                    </Col>
                    <Col md={4} xs="auto">
                        <Form.Control
                            type="number"
                            defaultValue={settings.investmentSize}
                            id="investmentSize"
                            onChange={(e) => setSelectedInvestmentSize(e.target.value)}
                        />
                    </Col>
                    <Col md={4} xs="auto">
                        <Select
                            defaultValue={defaultValue}
                            onChange={(format) =>{console.log(format.value); setCurrency(format.value)}}
                            options={currencyOptions}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InvestmentSizeModal;