import {auth, db} from "./firebase";
import {doc, updateDoc} from "firebase/firestore";

export function handleSignUp() {
    const user = auth.currentUser;
    return updateDoc(doc(db, "users", user.uid), { signedUp: true })
        .then((r) => {
            console.log("Successfully signed up!");
            return r;
        }).catch((firestoreError) => {
            console.error("Firestore error: ", firestoreError.message);
            throw firestoreError;  // Re-throw the error to be handled by the outer catch block
        });
}

export function handleSignOut() {
    const user = auth.currentUser;
    return updateDoc(doc(db, "users", user.uid), { signed_up: false })
        .then((r) => {
            console.log("Successfully de-registered ;(");
            return r;
        }).catch((firestoreError) => {
            console.error("Firestore error: ", firestoreError.message);
            throw firestoreError;  // Re-throw the error to be handled by the outer catch block
        });
}

