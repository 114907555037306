import {useEffect, useRef, useState} from 'react';
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import logo from '../assets/logo-dark-rect.jpeg';
import RegisterModal from "./modals/register_modal";
import LoginModal from "./modals/login_modal";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {handleLogout} from "./firebase/handle_logout";
import TimezoneModal from "./modals/timezone_modal";
import OddsFormatModal from "./modals/odds_format_modal";
import BookmakerModal from "./modals/bookmaker_modal";
import InvestmentSizeModal from "./modals/investment_size_modal";

import {handle_save_preferences} from "./firebase/handle_save";


function Header({settings, setSettings}) {

    const [expanded, setExpanded] = useState(false);

    const handleCloseNavbar = () => setExpanded(false);

    const navbarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarRef]);



    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showTimezone, setShowTimezone] = useState(false);
    const [showOddsFormat, setShowOddsFormat] = useState(false);
    const [showBookmakers, setShowBookmakers] = useState(false);
    const [showInvestmentSize, setShowInvestmentSize] = useState(false);

    const handleShowLogin = () => setShowLogin(true);
    const handleShowRegister = () => setShowRegister(true);
    const handleShowTimezone = () => setShowTimezone(true);
    const handleShowOddsFormat = () => setShowOddsFormat(true);
    const handleShowBookmakers = () => setShowBookmakers(true);
    const handleShowInvestmentSize = () => setShowInvestmentSize(true);


    const [registered, setRegistered] = useState(!!settings.user);

    const saveChangesToProfile = () => {
        handle_save_preferences(settings)
            .then(r => console.log("saved preferences"))
            .catch((error) => console.error(error.message));
    }


    return (
        <section className="bg-dark" style={{textAlign: "left"}}>
            <Navbar fixed="top" bg="dark" className="navbar-dark" ref={navbarRef} expanded={expanded} expand="lg" onToggle={(newExpanded) => setExpanded(newExpanded)}>
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Logo" width="80" height="45" className="d-inline-block align-text-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className="basic-navbar-nav">
                        <Nav className="my-2 my-lg-0 d-flex">
                            <Nav.Link as={HashLink} to="/#home" onClick={handleCloseNavbar}>Home</Nav.Link>
                            <NavDropdown  title="Betting" id="navbarScrollingDropdown">
                                <NavDropdown.Item as={HashLink} to="/#featured" onClick={handleCloseNavbar}>Featured Bets</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/upcoming" onClick={handleCloseNavbar}>Upcoming Games</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={HashLink} to="/free-trial" onClick={handleCloseNavbar}>Free trial</Nav.Link>
                            {/*<Nav.Link as={HashLink} to="/how-to" onClick={handleCloseNavbar}>How to</Nav.Link>*/}
                            {/*<Nav.Link href="#" disabled>*/}
                            {/*    Link*/}
                            {/*</Nav.Link>*/}
                        </Nav>
                        <Nav className="ms-auto">

                            <NavDropdown title={"Preferences"} align={"end"} >
                                <NavDropdown.Item onClick={handleShowTimezone} id="basic-nav-dropdown">Timezone: {settings.timezone.split("/")[1]}  </NavDropdown.Item>
                                <NavDropdown.Item onClick={handleShowOddsFormat} id="basic-nav-dropdown">Odds format: {settings.oddsFormat}  </NavDropdown.Item>
                                <NavDropdown.Item onClick={handleShowBookmakers} id="basic-nav-dropdown"> Bookmakers </NavDropdown.Item>
                                <NavDropdown.Item onClick={handleShowInvestmentSize} id="basic-nav-dropdown"> Investment Size </NavDropdown.Item>
                                {settings.user ? <NavDropdown.Item  onClick={saveChangesToProfile} id="basic-nav-dropdown"> Save to Profile </NavDropdown.Item> : <div></div>}

                            {/*className={"bg-secondary-subtle"} for the save button*/}
                            </NavDropdown>

                            <TimezoneModal show={showTimezone} setShow={setShowTimezone} settings={settings} setSettings={setSettings} />
                            <OddsFormatModal show={showOddsFormat} setShow={setShowOddsFormat} settings={settings} setSettings={setSettings}/>
                            <BookmakerModal show={showBookmakers} setShow={setShowBookmakers} settings={settings} setSettings={setSettings}/>
                            <InvestmentSizeModal show={showInvestmentSize} setShow={setShowInvestmentSize} settings={settings} setSettings={setSettings}/>


                            {settings.user ? (
                                <NavDropdown title={settings.username} align={"end"} id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} onClick={handleCloseNavbar} to="/account">My Account</NavDropdown.Item>
                                    {/*<NavDropdown.Item as={Link} to="/settings">Settings</NavDropdown.Item>*/}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => handleLogout(settings, setSettings)}>Sign Out</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <NavDropdown title="Account" align={"end"}>
                                    <NavDropdown.Item onClick={handleShowLogin} id="basic-nav-dropdown">Login </NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleShowRegister} id="basic-nav-dropdown">Register </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            <LoginModal show={showLogin} setShow={setShowLogin} setSettings={setSettings} setRegistered={setRegistered}/>
                            <RegisterModal show={showRegister} setShow={setShowRegister} settings={settings} setSettings={setSettings} setRegistered={setRegistered}/>

                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    );
}

export default Header;



// useEffect(() => {
//     // Firebase authentication state observer
//     const unsubscribe = auth.onAuthStateChanged(async (user_auth) => {
//         if (user_auth) {
//             // Update the settings object with the user info
//
//             console.log("user_auth: ", user_auth);
//             console.log("uid: ", user_auth.uid);
//             console.log("settings: ", settings);
//
//             // Fetch user data using the fetchData function
//             const userData = await fetchData({...settings, user: user_auth}, "User");
//
//             console.log(userData)
//
//
//
//             if (userData) {
//                 setSettings({...userData, user: user_auth});
//             } else {
//                 setSettings({...settings, user: user_auth, role: "basic"});
//             }
//
//         } else {
//             console.log("Signing user out")
//             setSettings({ ...settings, user: null });
//         }
//     });
//
//     return () => {
//         // Unsubscribe from the observer when the component unmounts
//         unsubscribe();
//     };
// }, []);