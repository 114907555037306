import {db} from "./firebase";
import {collection, query, doc, limit, getDocs, getDoc, orderBy} from "firebase/firestore";


function getNextFetchTime(now) {
    let nextFetch = new Date(now);

    if (now.getHours() < 4) {
        nextFetch.setHours(4, 15, 0, 0);
    } else if (now.getHours() < 8) {
        nextFetch.setHours(8, 15, 0, 0);
    } else if (now.getHours() < 12) {
        nextFetch.setHours(12, 15, 0, 0);
    } else if (now.getHours() < 16) {
        nextFetch.setHours(16, 15, 0, 0);
    } else if (now.getHours() < 20) {
        nextFetch.setHours(20, 15, 0, 0);
    } else {
        nextFetch.setHours(0, 15, 0, 0);
        nextFetch.setDate(now.getDate() + 1);  // set for next day
    }

    return nextFetch;
}

function isDataValid(cachedData, lastFetchedTimestamp) {
    // return false;
    const currentTime = new Date();
    let nextFetchTime = getNextFetchTime(lastFetchedTimestamp);

    return cachedData && currentTime < nextFetchTime;
}

export async function fetchData(settings, type) {
    console.log(settings.user)
    console.log(type)
    let cachedData;
    let lastFetchedTimestamp;

    try {
        cachedData = JSON.parse(localStorage.getItem(`cached${type}Data`));
        lastFetchedTimestamp = new Date(localStorage.getItem(`lastFetched${type}Timestamp`));
    } catch (error) {
        console.error("Error parsing data from localStorage:", error);
        return Promise.reject(error);
    }

    console.log("cachedData: ", cachedData);
    console.log("lastFetchedTimestamp: ", lastFetchedTimestamp);

    if (isDataValid(cachedData, lastFetchedTimestamp)) {
        // Use cached data
        console.log("Fetched from cache");
        return Promise.resolve(cachedData);
    } else {

        if (type !== "User") {
            const predictionsCollection = collection(db, "predictions");

            let q;


            console.log(type)
            if (type === "Featured") {
                q = query(predictionsCollection, orderBy("max_profit_value", "desc"), limit(20));
            } else if (type === "Upcoming") {
                q = query(predictionsCollection, orderBy("date"));
            } else {
                return Promise.reject(new Error("Invalid type"));
            }

            return getDocs(q)
                .then((querySnapshot) => {
                    const dataMap = {};
                    querySnapshot.docs.forEach(doc => {
                        dataMap[doc.id] = doc.data();
                    });

                    localStorage.setItem(`cached${type}Data`, JSON.stringify(dataMap));
                    localStorage.setItem(`lastFetched${type}Timestamp`, new Date().toISOString());

                    return dataMap;
                }).catch((error) => {
                    console.error("Error fetching data from Firestore:", error);
                    return Promise.reject(error);  // Propagate the error, so it can be handled by the caller
                });

        } else {
            console.log("settings.user.uid: below");
            console.log("settings.user.uid: ", settings.user.uid);
            const userDocRef = doc(collection(db, 'users'), settings.user.uid);
            const docSnapshot = await getDoc(userDocRef);
            if (docSnapshot.exists()) {
                localStorage.setItem(`cached${type}Data`, JSON.stringify(docSnapshot.data()));
                localStorage.setItem(`lastFetched${type}Timestamp`, new Date().toISOString());
                return docSnapshot.data();
            } else {
                return Promise.reject(new Error("User not found"));
            }

        }
    }
}

export function fetchSettings() {
    const defaultSettings = get_default_settings();
    return JSON.parse(localStorage.getItem('cachedSettings')) || defaultSettings;
    // const cachedSettingsString = localStorage.getItem('cachedSettings');
    // if (cachedSettingsString) {
    //     console.log("cached: " + cachedSettingsString)
    //     return JSON.parse(cachedSettingsString);
    // } else {
    //     console.log("default: " + defaultSettings)
    //     return defaultSettings;
    // }
}

export function get_default_settings() {
    return {
        timezone: 'America/Mexico_City',
        oddsFormat: 'EU',
        role: 'basic',
        investmentSize: 100,
        currency: '$',
        username: "Guest User",
        user: null,
        bookmakers: [],
        filterBookmakers: true,
        signedUp: false
    }
}




// function fetchData(type) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const dummyData = [
//                 {
//                     date: "2023-10-20",
//                     game: "Team A vs Team B",
//                     prediction: "Team A Win",
//                     minFairValue: "1.5",
//                     maxOffered: "1.8",
//                     expectedProfit: "15%"
//                 },
//                 {
//                     date: "2023-10-21",
//                     game: "Team C vs Team D",
//                     prediction: "Draw",
//                     minFairValue: "2.2",
//                     maxOffered: "2.4",
//                     expectedProfit: "9%"
//                 },
//                 //... Add more dummy data as needed
//             ];
//
//             resolve(dummyData);
//         }, 1000); // Simulating a 1-second delay, you can adjust as needed
//     });
// }