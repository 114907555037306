import { updateEmail } from "firebase/auth";
import { auth, db } from "./firebase";  // Adjust path if necessary
import { setDoc, doc } from "firebase/firestore";

export function handle_save_profile(email) {
    return updateEmail(auth.currentUser, email)
        .then(() => {
            console.log("Profile successfully updated!");
        })
        .catch((error) => {
            console.error("Profile update error: ", error.message);
            throw error;  // Re-throw the error to be handled by the outer catch block
        });
}

export function handle_save_preferences(settings) {
    const { user, ...otherSettings } = settings;
    return setDoc(doc(db, "users", auth.currentUser.uid), otherSettings)
        .then(() => {
            console.log("Document successfully updated!");
        })
        .catch((error) => {
            console.error("Firestore error: ", error.message);
            throw error;  // Re-throw the error to be handled by the outer catch block
        });
}
