import {useRef, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const MyTooltip = ({ text }) => {
    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    return (
        <OverlayTrigger
            show={showTooltip}
            placement="top"
            overlay={<Tooltip id={`tooltip-${text}`}>{text}</Tooltip>}
            delay={{ show: 250, hide: 400 }}
        >
            <i className="fa fa-info-circle"
               aria-hidden="true"
               ref={target}
               onMouseEnter={() => setShowTooltip(true)}
               onMouseLeave={() => setShowTooltip(false)}
               onClick={toggleTooltip}
            />
        </OverlayTrigger>
    );
};