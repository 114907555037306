import {auth} from "./firebase";


export function handleLogout(settings, setSettings) {
    auth.signOut()
        .then(() => {
            setSettings(settings => ({
                ...settings,
                user: null,
                username: "Guest User",
                role: "basic",
                signedUp: false
            }));
            console.log("User signed out successfully")
            // localStorage.delete('cachedUserData')
            // localStorage.delete('lastFetchedUserDataTimestamp')
        }).catch((error) => {
        // An error happened.
        console.error(error.message);
    });
}